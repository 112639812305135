import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas, faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"

import {
  starRatingContainer,
  selectedStarRatingContainer,
  associatedStarRatingContainer,
  starRatingSkillName,
  selectedStarRatingSkillName,
  highLight,
  lowLight,
  associate,
} from "../../sass/star-rating.module.scss"

library.add(fas, far, faStar, faStarHalfAlt)

// fa-border

const filledStar = (skillName, v) => (
  <FontAwesomeIcon icon={["fas", "star"]} key={`${skillName}-${v}`} />
)
const halfStar = (skillName, v) => (
  <FontAwesomeIcon icon="star-half-alt" key={`${skillName}-${v}`} />
)
const emptyStar = (skillName, v) => (
  <FontAwesomeIcon icon={["far", "star"]} key={`${skillName}-${v}`} />
)

const showRating = (skillName, rating) => {
  const starsArray = []
  for (let v = 0; v < 5; v++) {
    starsArray.push(rating--)
  }
  return starsArray.map(v =>
    v > 0.5
      ? filledStar(skillName, v)
      : v === 0.5
      ? halfStar(skillName, v)
      : emptyStar(skillName, v)
  )
}

const StarsRating = ({
  skillName,
  rating,
  isSelected = false,
  isAssociated = false,
}) => (
  <div
    className={
      isSelected
        ? selectedStarRatingContainer
        : isAssociated
        ? associatedStarRatingContainer
        : starRatingContainer
    }
  >
    <span
      className={isSelected ? selectedStarRatingSkillName : starRatingSkillName}
    >{`${skillName} `}</span>
    <span
      className={isSelected ? highLight : isAssociated ? associate : lowLight}
    >
      {showRating(skillName, rating)}
    </span>
  </div>
)

export default StarsRating
