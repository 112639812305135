import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StarRating from "../components/molecules/star-rating"

import {
  legend,
  mainContainer,
  lastVerticalLineRemoval,
} from "../sass/skills.module.scss"

const Skills = ({ data: { allContentfulSkillsPage } }) => {
  const skillsArray = allContentfulSkillsPage.edges[0].node.skills
  const [selected, setSelected] = useState("")

  const relatedMap = {}
  skillsArray.forEach(({ skillName }) => (relatedMap[`${skillName}`] = []))

  skillsArray.forEach(({ skillName, related }) => {
    related &&
      related.forEach(
        r =>
          relatedMap[`${r.skillName}`] &&
          relatedMap[`${r.skillName}`].push(skillName)
      )
  })

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | Skills" />
      <div className={legend}>
        Click on a skill to see complementary knowledge!
      </div>
      <div className={mainContainer}>
        {skillsArray.map(({ skillName, rating }) => {
          return (
            <div
              key={skillName}
              role="button"
              tabIndex={0}
              onClick={() =>
                setSelected(
                  selected === "" || skillName !== selected ? skillName : ""
                )
              }
              onKeyDown={() => {}}
            >
              <StarRating
                skillName={skillName}
                rating={rating}
                isSelected={selected === skillName}
                isAssociated={relatedMap[`${skillName}`].includes(selected)}
              />
            </div>
          )
        })}
        <div className={lastVerticalLineRemoval}></div>
      </div>
    </Layout>
  )
}

export default Skills

export const query = graphql`
  query SkillsQuery {
    allContentfulSkillsPage {
      edges {
        node {
          skills {
            skillName
            rating
            related {
              skillName
            }
          }
        }
      }
    }
  }
`
